<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Wettkämpfe"
      color="primary"
    >
      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <EventTable
        :events="events"
      />
    </base-material-card>
    <base-material-card
      icon="far fa-users"
      title="alle Turner des Vereins"
      color="primary"
    >
      <v-data-table
        :items="allathletes"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item="{item}">
          <tr>
            <td>{{ item.person | person }}</td>
            <td class="text-center">{{ item.team ? 'X' : '' }}</td>
            <td v-for="m in matchdays" :key="`${m._id}-${item.person._id}`" class="text-center">
              {{ einsatz(item.person._id, m._id) }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <b>Hinweise:</b>
      <ul>
        <li>Hier werden alle Turner aller Mannschaften dieses Vereins aufgelistet.</li>
        <li>Die Turner, die in dieser Mannschaft gemeldet wurden sind entsprechend in der zweiten Spalte markiert.</li>
        <li>Durch Verlegungen von Wettkämpfen kann es ggf. vorkommen, dass die Wettkämofe nicht in der ausgelosten Reihenfolge geturnt werden. Diese ist aber für das Startrecht ausschlaggebend!</li>
        <li>Bei früheren Wettkämpftagen darf in der selben oder einer niedrigeren Liga geturnt werden.</li>
        <li>Bei spätereren Wettkampftagen darf in der selben oder einer höheren Liga geturnt werden.</li>
      </ul>
    </base-material-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbM2021League {
    parent {
      _id
      ... on StbM2021 {
        matchdays { _id name startDate }
      }
    }
    name
    shortName
    order
    team(team: $team) {
      athletes {
        person { _id givenName familyName }
      }
      team { _id name }
    }
    events(team: $team) {
      _id
      matchday { _id name }
      name
      startDate
      publicComment
      status
      mode
      teams {
        team { _id name }
        home
        result { final score gp tp }
        athletes { _person }
      }
      venue {
        _id
        name
        address { streetAddress streetNumber postalCode addressLocality }
      }
    }
  }
`

export default {
  name: 'start',

  components: {
    EventTable: () => import('../components/EventTable')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    ClubTeam: {},
    filter: '',
    other: []
  }),

  computed: {
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...curr.events.map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    },
    allevents () {
      return [
        ...(this.EventFind?.reduce((a, c) => { a.push(...((c?.events || []).map(e => ({ ...e, liga: c.shortName })))); return a }, []) || []),
        ...(this.other?.reduce((a, c) => { a.push(...((c?.events || []).map(e => ({ ...e, liga: c.shortName })))); return a }, []) || [])
      ]
    },
    athletes () {
      return this.EventFind.find(e => !!e.team)?.team?.athletes?.slice()?.sort((a, b) => {
        if (a.person.familyName !== b.person.familyName) return a.person.familyName < b.person.familyName ? -1 : 1
        return a.person.givenName < b.person.givenName ? -1 : 1
      }) || []
    },
    otherteams () {
      return this.ClubTeam?.club?.teams?.filter(t => t._id !== this.team)
    },
    matchdays () {
      return this.EventFind?.[0]?.parent?.matchdays?.slice()?.sort((a, b) => a.startDate < b.startDate ? -1 : 1) || []
    },
    allathletes () {
      const result = [
        ...((this.EventFind.find(e => !!e.team)?.team?.athletes || []).map(a => ({ ...a, team: true }))),
        ...((this.other?.reduce((a, c) => { a.push(...c?.team?.athletes || []); return a }, []) || []).map(a => ({ ...a, team: false })))
      ]?.slice()?.reduce((acc, curr) => {
        if (!acc.find(a => a.person._id === curr.person._id)) {
          acc.push(curr)
        }
        return acc
      }, [])?.sort((a, b) => {
        if (a.person.familyName !== b.person.familyName) return a.person.familyName < b.person.familyName ? -1 : 1
        return a.person.givenName < b.person.givenName ? -1 : 1
      }) || []

      return result
    },
    headers () {
      return [
        { text: 'Turner', value: 'person', sortable: false },
        { text: 'in dieser Mannschaft gemeldet', value: 'team', sortable: false, align: 'center' },
        ...this.matchdays.map(m => ({
          text: m.name,
          value: m._id,
          sortable: false,
          align: 'center'
        }))
      ]
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/view/${item._id}`)
    },
    openmaps (address) {
      const a = `${address.streetAddress} ${address.streetNumber}, ${address.postalCode}, ${address.addressLocality}`
      window.open(`https://www.google.de/maps/place/${a.replaceAll(' ', '+')}`, '_blank')
    },
    einsatz (_person, _matchday) {
      const events = this.allevents.filter(e => e.matchday._id === _matchday && !!e.teams?.find(t => !!t?.athletes?.find(a => a._person === _person)))
      return events.map(e => e.liga).join(',')
    }
  },

  watch: {
    async otherteams () {
      this.other = await Promise.all(this.otherteams.map(o => new Promise(resolve => {
        this.query({
          query: gql`
          query($parent: UUID, $team: UUID!) { EventFind(parent: $parent) { ${query} }}
        `,
          variables: {
            parent: this.id,
            team: o._id
          }
        }).then(result => resolve(result.EventFind.find(e => !!e.team)))
      })))
    }
  },

  apollo: {
    ClubTeam: {
      query: gql`
         query($id: UUID!) { ClubTeam(id: $id) { _id club { _id teams { _id name }}}}
      `,
      variables () {
        return {
          id: this.team
        }
      }
    },
    EventFind: {
      query: gql`
          query($parent: UUID, $team: UUID!) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id,
          team: this.team
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID, $team: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id,
            team: this.team
          }
        }
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
